import images from './images';

const starters = [
  {
    title: 'Baby corn chilli',
    price: 'Rs.150',
    tags: 'SPICY | VEG',
  },
  {
    title: 'Paneer chilli',
    price: 'Rs.200',
    tags: 'SPICY | VEG',
  },
  {
    title: 'Chola bhatura',
    price: 'Rs.150',
    tags: 'SPICY | VEG',
  },
  {
    title: 'Paneer pakora',
    price: 'Rs.200',
    tags: 'SNACKS | VEG',
  },
  {
    title: 'Manchurian',
    price: 'Rs.180',
    tags: 'DRY | VEG',
  },
];

const mainCourse = [
  {
    title: 'Aperol Sprtiz',
    price: '$20',
    tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '$16',
    tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Daiquiri',
    price: '$10',
    tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Old Fashioned',
    price: '$31',
    tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Negroni',
    price: '$26',
    tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Best Deoghar Restaurant',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Quality Food Award',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { starters, mainCourse, awards };
